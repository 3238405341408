.main-gradient-background {
  background: radial-gradient(115.94% 422.29% at -15.94% 108.5%, #0c923f 0%, #00461c 100%);
  border: none;
}

.light-green-background {
  background-color: #d9e9dc !important;
  border: none;
}

.main-green-background {
  background-color: #00461c !important;
  border: none;
}

.light-green-gradient-background {
  background: linear-gradient(170.34deg, #0db14b 14.12%, #009944 54.84%, #009944 90.95%) !important;
  border: none;
}

.main-red-background {
  background-color: rgb(231, 76, 60) !important;
  border: none;
}

.main-red-background:hover {
  background-color: rgb(231, 76, 60) !important;
}

.main-red-background:focus {
  background-color: rgb(231, 76, 60) !important;
}

.light-grey-background {
  background-color: #ededed;
}
.dark-grey-background {
  background-color: #aaa !important;
}
